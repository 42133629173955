"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.v2DefaultBranchUrl = exports.v2BranchUrl = exports.dashboard = exports.teamInviteLink = exports.docsUrl = exports.getSandboxId = exports.privacyUrl = exports.tosUrl = exports.curatorUrl = exports.searchUrl = exports.gitHubToProjectsUrl = exports.gitHubToSandboxUrl = exports.optionsToParameterizedUrl = exports.githubRepoUrl = exports.profileLikesUrl = exports.profileSandboxesUrl = exports.teamSettingsUrl = exports.teamOverviewUrl = exports.exploreUrl = exports.dashboardUrl = exports.profileUrl = exports.signInVercelUrl = exports.signInUrl = exports.signInPageUrl = exports.forkSandboxUrl = exports.frameUrl = exports.embedUrl = exports.sandboxUrl = exports.v2EditorUrl = exports.editorUrl = exports.uploadFromCliUrl = exports.newCxJSSandboxUrl = exports.newAngularSandboxUrl = exports.newSvelteSandboxUrl = exports.importFromGitHubUrl = exports.newVueSandboxUrl = exports.newPreactSandboxUrl = exports.newDojoSandboxUrl = exports.newReactTypeScriptSandboxUrl = exports.parcelSandboxUrl = exports.newSandboxUrl = exports.newSandboxWizard = exports.protocolAndHost = exports.host = exports.CSBProjectGitHubRepository = exports.gitHubRepoPattern = void 0;
const is_server_1 = require("../templates/helpers/is-server");
const dashboard = __importStar(require("./url-generator/dashboard"));
exports.dashboard = dashboard;
exports.gitHubRepoPattern = /(https?:\/\/)?((www.)?)github.com(\/[\w-]+){2,}/;
const gitHubPrefix = /(https?:\/\/)?((www.)?)github.com/;
const dotGit = /(\.git)$/;
const sandboxHost = {
    'https://codesandbox.io': 'https://csb.app',
    'https://codesandbox.stream': 'https://codesandbox.dev',
};
const CSBProjectGitHubRepository = ({ owner, repo, welcome, }) => {
    const origin = process.env.STAGING_API
        ? 'https://codesandbox.stream'
        : 'https://codesandbox.io';
    return `${origin}/p/github/${owner}/${repo}?create=true${welcome ? '&welcome=true' : ''}`;
};
exports.CSBProjectGitHubRepository = CSBProjectGitHubRepository;
const buildEncodedUri = (strings, ...values) => strings[0] +
    values
        .map((value, i) => `${encodeURIComponent(value)}${strings[i + 1]}`)
        .join('');
const REGEX = /(?<id>\w{5,6})-(?<port>\d{1,5})\.(?<hostname>.*)/;
function getCodeSandboxDevHost(port) {
    if (typeof window === 'undefined') {
        // eslint-disable-next-line global-require
        const hostname = require('os').hostname();
        return `${hostname}-${port}.preview.csb.app`;
    }
    const currentUrl = location.host;
    const currentMatch = currentUrl.match(REGEX);
    if (!(currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.groups)) {
        return undefined;
    }
    const { id, hostname } = currentMatch.groups;
    if (!id || !port || !hostname) {
        return undefined;
    }
    return `${id}-${port}.${hostname}`;
}
const host = () => {
    if (process.env.CSB && getCodeSandboxDevHost(3000)) {
        return getCodeSandboxDevHost(3000);
    }
    if (process.env.NODE_ENV === 'production') {
        return process.env.CODESANDBOX_HOST.split('//')[1];
    }
    if (process.env.LOCAL_SERVER) {
        return 'localhost:3000';
    }
    return process.env.DEV_DOMAIN;
};
exports.host = host;
const protocolAndHost = () => `${location.protocol}//${exports.host()}`;
exports.protocolAndHost = protocolAndHost;
const newSandboxWizard = () => `/s`;
exports.newSandboxWizard = newSandboxWizard;
const newSandboxUrl = () => `/s/new`;
exports.newSandboxUrl = newSandboxUrl;
const parcelSandboxUrl = () => `/s/vanilla`;
exports.parcelSandboxUrl = parcelSandboxUrl;
const newReactTypeScriptSandboxUrl = () => `/s/react-ts`;
exports.newReactTypeScriptSandboxUrl = newReactTypeScriptSandboxUrl;
const newDojoSandboxUrl = () => `/s/github/dojo/dojo-codesandbox-template`;
exports.newDojoSandboxUrl = newDojoSandboxUrl;
const newPreactSandboxUrl = () => `/s/preact`;
exports.newPreactSandboxUrl = newPreactSandboxUrl;
const newVueSandboxUrl = () => `/s/vue`;
exports.newVueSandboxUrl = newVueSandboxUrl;
const importFromGitHubUrl = () => `/s/github`;
exports.importFromGitHubUrl = importFromGitHubUrl;
const newSvelteSandboxUrl = () => `/s/svelte`;
exports.newSvelteSandboxUrl = newSvelteSandboxUrl;
const newAngularSandboxUrl = () => `/s/angular`;
exports.newAngularSandboxUrl = newAngularSandboxUrl;
const newCxJSSandboxUrl = () => `/s/github/codaxy/cxjs-codesandbox-template`;
exports.newCxJSSandboxUrl = newCxJSSandboxUrl;
const uploadFromCliUrl = () => `/s/cli`;
exports.uploadFromCliUrl = uploadFromCliUrl;
const sandboxGitUrl = (git) => buildEncodedUri `github/${git.username}/${git.repo}/tree/${git.branch}/` +
    git.path;
const editorUrl = () => `/s/`;
exports.editorUrl = editorUrl;
const v2EditorUrl = () => `/p/`;
exports.v2EditorUrl = v2EditorUrl;
const sandboxUrl = (sandboxDetails) => {
    const baseUrl = sandboxDetails.isV2
        ? `${exports.v2EditorUrl()}sandbox/`
        : exports.editorUrl();
    let queryParams = '';
    if (sandboxDetails.query) {
        queryParams = `?${new URLSearchParams(sandboxDetails.query).toString()}`;
    }
    if (sandboxDetails.git) {
        const { git } = sandboxDetails;
        return `${baseUrl}${sandboxGitUrl(git)}${queryParams}`;
    }
    if (sandboxDetails.alias) {
        return `${baseUrl}${sandboxDetails.alias}${queryParams}`;
    }
    return `${baseUrl}${sandboxDetails.id}${queryParams}`;
};
exports.sandboxUrl = sandboxUrl;
const embedUrl = (sandbox) => {
    if (sandbox.git) {
        const { git } = sandbox;
        return `/embed/${sandboxGitUrl(git)}`;
    }
    if (sandbox.alias) {
        return `/embed/${sandbox.alias}`;
    }
    return `/embed/${sandbox.id}`;
};
exports.embedUrl = embedUrl;
const stagingFrameUrl = (shortid, path) => {
    const stagingHost = (process.env.CODESANDBOX_HOST
        ? process.env.CODESANDBOX_HOST
        : '').split('//')[1];
    const segments = stagingHost.split('.');
    const first = segments.shift();
    return `${location.protocol}//${first}-${shortid}.${segments.join('.')}/${path}`;
};
const frameUrl = (sandbox, append = '', { useFallbackDomain = false, port = undefined, } = {}) => {
    const path = append.indexOf('/') === 0 ? append.substr(1) : append;
    const templateIsServer = is_server_1.isServer(sandbox.template);
    if (process.env.LOCAL_SERVER) {
        if (templateIsServer) {
            return `${location.protocol}//${sandbox.id}${port ? `-${port}` : ''}.${templateIsServer ? 'sse.' : ''}${process.env.STAGING_API ? 'codesandbox.stream' : 'codesandbox.io'}/${path}`;
        }
        return `http://localhost:3002/${path}`;
    }
    if (process.env.STAGING) {
        return stagingFrameUrl(sandbox.id, path);
    }
    let sHost = exports.host();
    if (`https://${sHost}` in sandboxHost &&
        !useFallbackDomain &&
        !templateIsServer) {
        sHost = sandboxHost[`https://${sHost}`].split('//')[1];
    }
    return `${location.protocol}//${sandbox.id}${port ? `-${port}` : ''}.${templateIsServer ? 'sse.' : ''}${sHost}/${path}`;
};
exports.frameUrl = frameUrl;
const forkSandboxUrl = (sandbox) => `${exports.sandboxUrl(sandbox)}/fork`;
exports.forkSandboxUrl = forkSandboxUrl;
const signInPageUrl = (redirectTo) => {
    let url = `/signin`;
    if (redirectTo) {
        url += '?continue=' + redirectTo;
    }
    return url;
};
exports.signInPageUrl = signInPageUrl;
const signInUrl = (extraScopes = false) => '/auth/github' +
    (extraScopes ? '?scope=user:email,public_repo,workflow' : '');
exports.signInUrl = signInUrl;
const signInVercelUrl = () => '/auth/vercel';
exports.signInVercelUrl = signInVercelUrl;
const profileUrl = (username) => `/u/${username}`;
exports.profileUrl = profileUrl;
const dashboardUrl = () => `/dashboard`;
exports.dashboardUrl = dashboardUrl;
const exploreUrl = () => `/explore`;
exports.exploreUrl = exploreUrl;
const teamOverviewUrl = (teamId) => `/dashboard/teams/${teamId}`;
exports.teamOverviewUrl = teamOverviewUrl;
const teamSettingsUrl = () => `/dashboard/settings`;
exports.teamSettingsUrl = teamSettingsUrl;
const profileSandboxesUrl = (username, page) => `${exports.profileUrl(username)}/sandboxes${page ? `/${page}` : ''}`;
exports.profileSandboxesUrl = profileSandboxesUrl;
const profileLikesUrl = (username, page) => `${exports.profileUrl(username)}/likes${page ? `/${page}` : ''}`;
exports.profileLikesUrl = profileLikesUrl;
const githubRepoUrl = ({ repo, branch, username, path, }) => buildEncodedUri `https://github.com/${username}/${repo}/tree/${branch}/` +
    path;
exports.githubRepoUrl = githubRepoUrl;
const optionsToParameterizedUrl = (options) => {
    const keyValues = Object.keys(options)
        .sort()
        .filter(a => options[a])
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`)
        .join('&');
    return keyValues ? `?${keyValues}` : '';
};
exports.optionsToParameterizedUrl = optionsToParameterizedUrl;
const gitHubToSandboxUrl = (githubUrl) => githubUrl.replace(gitHubPrefix, '/s/github').replace(dotGit, '');
exports.gitHubToSandboxUrl = gitHubToSandboxUrl;
const gitHubToProjectsUrl = (githubUrl) => githubUrl.replace(gitHubPrefix, '/p/github').replace(dotGit, '');
exports.gitHubToProjectsUrl = gitHubToProjectsUrl;
const searchUrl = (query) => `/search${query ? `?query=${query}` : ''}`;
exports.searchUrl = searchUrl;
const curatorUrl = () => `/curator`;
exports.curatorUrl = curatorUrl;
const tosUrl = () => `/legal/terms`;
exports.tosUrl = tosUrl;
const privacyUrl = () => `/legal/privacy`;
exports.privacyUrl = privacyUrl;
function getSandboxId() {
    const csbHost = process.env.CODESANDBOX_HOST;
    if (process.env.LOCAL_SERVER) {
        return document.location.hash.replace('#', '');
    }
    if (process.env.STAGING) {
        const segments = csbHost.split('//')[1].split('.');
        const first = segments.shift();
        const re = RegExp(`${first}-(.*)\\.${segments.join('\\.')}`);
        return document.location.host.match(re)[1];
    }
    let result;
    [csbHost, sandboxHost[csbHost]].filter(Boolean).forEach(tryHost => {
        const hostRegex = tryHost.replace(/https?:\/\//, '').replace(/\./g, '\\.');
        const sandboxRegex = new RegExp(`(.*)\\.${hostRegex}`);
        const matches = document.location.host.match(sandboxRegex);
        if (matches) {
            result = matches[1];
        }
    });
    if (!result) {
        throw new Error(`Can't detect sandbox ID from the current URL`);
    }
    return result;
}
exports.getSandboxId = getSandboxId;
const docsUrl = (path = '') => `https://codesandbox.io/docs${path}`;
exports.docsUrl = docsUrl;
const teamInviteLink = (inviteToken) => `${exports.protocolAndHost()}/invite/${inviteToken}`;
exports.teamInviteLink = teamInviteLink;
// This function handles all the scenarios of v2 branch editor urls
// It is not exported from the package to avoid miss-using it
const v2EditorBranchUrl = ({ owner, repoName, branchName, workspaceId, createDraftBranch, importFlag, source, }) => {
    const queryString = new URLSearchParams(Object.assign(Object.assign(Object.assign(Object.assign({}, (workspaceId ? { workspaceId } : {})), (createDraftBranch ? { create: 'true' } : {})), (importFlag ? { import: 'true' } : {})), (source ? { utm_source: source } : {}))).toString();
    return `${exports.v2EditorUrl()}github/${owner}/${repoName}${branchName ? '/' + branchName : ''}${queryString ? '?' + queryString : ''}`;
};
const v2BranchUrl = (params) => {
    return v2EditorBranchUrl(params);
};
exports.v2BranchUrl = v2BranchUrl;
const v2DefaultBranchUrl = (params) => {
    return v2EditorBranchUrl(params);
};
exports.v2DefaultBranchUrl = v2DefaultBranchUrl;
