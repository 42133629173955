"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.proUrl = exports.discoverSearch = exports.discover = exports.search = exports.permissionSettings = exports.registrySettings = exports.settings = exports.liked = exports.shared = exports.deleted = exports.recent = exports.templates = exports.syncedSandboxes = exports.repository = exports.repositories = exports.myContributions = exports.drafts = exports.sandboxes = exports.ALL_SANDBOXES_URL_PREFIX = exports.DASHBOARD_URL_PREFIX = void 0;
exports.DASHBOARD_URL_PREFIX = '/dashboard';
exports.ALL_SANDBOXES_URL_PREFIX = `${exports.DASHBOARD_URL_PREFIX}/sandboxes`;
function appendTeamIdQueryParam(url, teamId) {
    if (teamId) {
        return `${url}?workspace=${teamId}`;
    }
    return url;
}
function sanitizePath(path) {
    return path
        .split('/')
        .map(p => p.split(' ').map(encodeURIComponent).join(' '))
        .join('/');
}
const sandboxes = (path, teamId) => appendTeamIdQueryParam(`${exports.ALL_SANDBOXES_URL_PREFIX}${sanitizePath(path)}`, teamId);
exports.sandboxes = sandboxes;
const drafts = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/drafts`, teamId);
exports.drafts = drafts;
const myContributions = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/my-contributions`, teamId);
exports.myContributions = myContributions;
const repositories = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/repositories`, teamId);
exports.repositories = repositories;
const repository = ({ owner, name, teamId, }) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/repositories/github/${owner}/${name}`, teamId);
exports.repository = repository;
const syncedSandboxes = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/synced-sandboxes`, teamId);
exports.syncedSandboxes = syncedSandboxes;
const templates = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/templates`, teamId);
exports.templates = templates;
const recent = (teamId, extraParams) => {
    let recentUrl = appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/recent`, teamId);
    if (extraParams && Object.keys(extraParams).length > 0) {
        const params = new URLSearchParams(extraParams);
        if (recentUrl.includes('?')) {
            recentUrl += '&';
        }
        else {
            recentUrl += '?';
        }
        recentUrl += params.toString();
    }
    return recentUrl;
};
exports.recent = recent;
const deleted = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/deleted`, teamId);
exports.deleted = deleted;
const shared = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/shared`, teamId);
exports.shared = shared;
const liked = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/liked`, teamId);
exports.liked = liked;
const settings = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/settings`, teamId);
exports.settings = settings;
const registrySettings = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/settings/npm-registry`, teamId);
exports.registrySettings = registrySettings;
const permissionSettings = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/settings/permissions`, teamId);
exports.permissionSettings = permissionSettings;
const search = (query, teamId) => {
    let searchUrl = appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/search`, teamId);
    if (searchUrl.includes('?')) {
        searchUrl += '&';
    }
    else {
        searchUrl += '?';
    }
    searchUrl += `query=${query}`;
    return searchUrl;
};
exports.search = search;
const discover = (teamId, albumId) => {
    if (albumId) {
        return appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/discover/${albumId}`, teamId);
    }
    return appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/discover`, teamId);
};
exports.discover = discover;
const discoverSearch = (query, teamId) => {
    let searchUrl = appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/discover/search`, teamId);
    if (searchUrl.includes('?'))
        searchUrl += '&';
    else
        searchUrl += '?';
    searchUrl += `query=${query}`;
    return searchUrl;
};
exports.discoverSearch = discoverSearch;
const proUrl = ({ workspaceId, source } = {}) => {
    const searchQuery = new URLSearchParams({});
    if (workspaceId) {
        searchQuery.set('workspace', workspaceId);
    }
    if (source) {
        searchQuery.set('utm_source', source);
    }
    const queryString = searchQuery.toString();
    return queryString ? `/pro?${queryString}` : '/pro';
};
exports.proUrl = proUrl;
